import { Link } from "react-router-dom";

const BottomNav = () => {
    return (
    <div>
        <div className="bottom-nav">
            <h2>Designed and Developed by Miles Herrman - 2023</h2>
            <div className="site-links">
                <Link to="/">Home</Link>
                <Link to="/education">Education</Link>
                <Link to="/skills">Skills</Link>
                <Link to="/resume">Resume</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <Link to="/Privacy">Privacy Policy</Link>
        </div>
    </div> )
  };
   
  export default BottomNav;