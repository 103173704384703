import resume from './images/webresume.pdf';
//import resumeDownload from './images/resume.pdf'


function Resume() {

  const downloadTxtFile = () => {
    fetch('resume.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'MilesHerrmanResume.pdf';
          alink.click();
      })
    })
  }

  return (
    <div className="resume">
    <h1 className="main-title">Resume</h1>
      <header className="resume-header">
        <div className='text'>
          <h2>What you've all been waiting for...</h2>
          <div className="btnDiv">
            <button id="downloadBtn" onClick={downloadTxtFile} value="download">Download PDF</button>
          </div>
        </div>
        <div className='pdf-container'>
          <embed src={resume} type="application/pdf" className="embed-container" />
        </div>
      </header>
    </div>

  );
  }
  
  export default Resume;