import { Link } from 'react-router-dom';
import { useViewport } from './Viewport'
import { useState, useEffect } from 'react';


function NavDrop(props) {
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    function handleScroll() {
        setOpen(false);
    };


    return (
      <li className="nav-item">
        <button className="icon-button" onClick={() => setOpen(!open)}>
            {props.icon}
        </button>
        {open && props.children}
      </li>
    );
}

const flipTheme = () => {
    document.body.classList.toggle("dark-theme");
   }

  
const DesktopComponent = () => (
    <div className="navbar">
            <div className="desktop-link">
                <Link to="/">Home</Link>
                <Link to="/education">Education</Link>
                <Link to="/skills">Skills</Link>
            </div>
            <div className="desktop-name">
                <Link to="/">Miles Herrman</Link>
            </div>
            <div className="desktop-link">
                <Link to="/resume">Resume</Link>
                <Link to="/contact">Contact</Link>
                <div className="button r" id="button-3">
                    <input type="checkbox" className="checkbox" onClick={() => flipTheme()}/>
                    <div className="knobs"></div>
                    <div className="layer"></div>
                </div>
            </div>
    </div>
);

const MobileComponent = () => (
    <div className="navbar">
        <div className="mobile-link">
            <NavDrop icon="⬇">
                <div  className="dropdown">
                    <Link to="/">Home.</Link>
                    <Link to="/education">Education.</Link>
                    <Link to="/skills">Skills.</Link>
                    <Link to="/resume">Resume.</Link>
                    <Link to="/contact">Contact.</Link>
                </div>
            </NavDrop>
        </div>
        <div className="mobile-name">
            <Link to="/">Miles Herrman.</Link>
        </div>
        <div className ="mobile-button">
            <div className="button r" id="button-3">
                <input type="checkbox" className="checkbox" onClick={() => flipTheme()}/>
                <div className="knobs"></div>
                <div className="layer"></div>
            </div>
        </div>
    </div> 
);

const Navbar = () => {
  const { width } = useViewport();
  const breakpoint = 775;

  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
};
 
export default Navbar;  