import { useState, useEffect } from "react";
import logo1 from './images/logo.png';
import github from './images/github.png'
import linkedin from './images/linkedin.png'
import instagram from './images/instagram.png'


function Contact() {
    const [invert, setInvert] = useState(false)
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Do something with the form data, e.g. send it to a server
      console.log(formData);
      // Reset the form
      setFormData({ name: "", email: "", subject: "", message: "" });
    };

    const [logo, setLogo] = useState(logo1)

    
    useEffect(() => {
      const handleBodyClassChange = () => {
        if (document.body.classList.contains('dark-theme')) {
          setInvert(true)
        } else {
          setInvert(false)
        }

      };
      document.body.addEventListener('change', handleBodyClassChange);
      return () => {
        document.body.removeEventListener('change', handleBodyClassChange);
      };
    }, [document.body.classList]);
    
    useEffect(() => {
        if (document.body.classList.contains('dark-theme')) {
          setInvert(true)
        } else {
          setInvert(false)
        }
    }, []);

    window.addEventListener('load', function() {
      const logo = document.getElementById('logo');
      logo.classList.add('translate');
    });

    return (
      <div className="contact">
        <h1 className="main-title">GET IN TOUCH</h1>
        <header className="contact-header">
          <div className="important">
            <div className="logo-container">
              <img src={logo} className="logo" id="logo" alt="miles herrman logo" style={{ filter: invert ? 'invert(100%)' : 'none' }}/>
            </ div>
            <div className="contact-container">
              <form className="contact-form" onSubmit={handleSubmit}>
                <input
                type="text"
                id="name"
                name="name"
                placeholder="Name *"
                value={formData.name}
                onChange={handleInputChange}
                required
                />

                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email *" 
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />

                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                />

                <textarea
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleInputChange}
                  rows={5}
                />

                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
       
          <div className="links">
            <br />
            <h2>Email:</h2> 
            <br />
            <h2>herrman@calpoly.edu</h2>
            <br />
            <br />
            <br />
            <br />
            <h2>Social Links:</h2>
            <br />
            <div className="social-logos">
              <a href="https://www.github.com/milesherrman" target="_blank" rel="noopener noreferrer">
                <img src={github} className="github" alt="github-logo" style={{ filter: invert ? 'invert(100%)' : 'none' }}/>
              </a>
              <a href="https://www.linkedin.com/in/milesherrman/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} className="linkedIn" alt="linkedIn-logo" style={{ filter: invert ? 'invert(100%)' : 'none' }}/>
              </a>
              <a href="https://www.instagram.com/miles_herrman" target="_blank" rel="noopener noreferrer">
                <img src={instagram} className="instagram" alt="instagram-logo" style={{ filter: invert ? 'invert(100%)' : 'none' }}/>
              </a>              
            </div>
            <br />
            <br />
            <br />

        </div>
    
        </header>
      </div>
    );
  }
  
  export default Contact;