import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <div className="not-found">
            <h1>Sorry!</h1>
            <br />
            <h2>That page cannot be found   :/</h2>
            <br />
            <h2>I highly doubt the developer has a bug in his code so you're probably in the wrong here...</h2>
            <br />
            <Link to="/">I think we should just head back to the home page and forget this happened</Link>
        </div>
     );
}
 
export default NotFound;