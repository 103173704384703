//import each component into main app
import Navbar from './Navbar';
import BottomNav from './BottomNav';
import Home from './Home';
import NotFound from './NotFound'
import Resume from './Resume';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ViewportProvider } from './Viewport';
import Education from './Education';
import Skills from './Skills';
import Contact from './Contact';
import Privacy from './Privacy';
import { ScrollToTop } from './Components';
import { useHistory } from 'react-router-dom';
import { useRef, useEffect } from 'react';

function App() {

  const history = useHistory();
  const contentRef = useRef(null);
    
  useEffect(() => {
    const currentRef = contentRef.current; // copy the current value to a variable
    return () => {
      if (history && currentRef) {
        currentRef.classList.add("page-exit");
        setTimeout(() => {
          history.push("/new-page");
        }, 500);
      }
    };
  }, [history]);
  

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <ViewportProvider>
          <Navbar />
        </ViewportProvider>
        <div className="content" ref={contentRef}>
          <Switch>
            <Route exact path="/"> 
              <Home />
            </Route>
            <Route exact path="/education">       
              <Education />
            </Route>
            <Route exact path="/skills">
              <Skills />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/resume">
              <Resume />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div> 
      <BottomNav />
      </div>
    </Router>
  );
}

export default App;
