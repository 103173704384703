import { React, useEffect, useRef } from 'react';
import Typewriter from 'typewriter-effect';
import { useLocation } from 'react-router-dom';
import './index.css';
import * as THREE from 'three';



function Typewrite(props){ 
  return (
    <div>
    <Typewriter className="typing" onInit={(typewriter) => {
        typewriter.typeString(props.text).pauseFor(2500).deleteAll().start();
      }}
    />
    </div>
  );
}

function TypewriteNoDelete(props){
  return (
    <Typewriter className="typing"
      onInit={(typewriter) => {
      typewriter.typeString(props.text).start();
    }}
  />
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function Cube(props) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#00b8ff");
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
    renderer.setSize(window.innerWidth, window.innerHeight);

    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.LineSegments(
      edges,
      new THREE.LineBasicMaterial({ color: 0x000000 })
    );
    const cube = new THREE.Mesh(geometry, material);
    cube.add(line);
    scene.add(cube);

    camera.position.z = 5;

    const animate = function () {
      requestAnimationFrame(animate);

      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;

      renderer.render(scene, camera);
    };

    animate();
  }, []);

  return <canvas ref={canvasRef} />;
}


export { Typewrite, TypewriteNoDelete, ScrollToTop, Cube };