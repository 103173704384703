import student from './images/wow.jpeg';
import run from './images/running1.jpeg';
import snow from './images/snow.jpg';
import hike from './images/backpack.JPG';
import { useState, useCallback, useEffect } from 'react';
import { Typewrite } from "./Components";


// import { Cube } from './Components';
// import { Scene as scene, Camera as camera } from 'three';

function Home() {

  const description = [' full time student', ' running enjoyer', ' weekend adventurer', ' nature enthusiast'];
  const images = [student, run, hike, snow];
  const [index, setIndex] = useState(0); 

  function RotatingText(){
    
    const shuffle = useCallback(() => {
      if (index < description.length - 1){ setIndex(index + 1); }
      else { setIndex(0); }
    }, []);
  
    useEffect(() => {
        const intervalID = setInterval(shuffle, 9500);
        return () => clearInterval(intervalID);
    }, [shuffle])
  
    return(
        <div className="rotating-text">
          <Typewrite text={description[index]} />
        </div>      
    )
  }
  
  function RotatingImage(props){
    const shuffle = useCallback(() => {
      if (index < images.length - 1){ setIndex(index + 1); }
      else { setIndex(0); }
    }, []);
  
    useEffect(() => {
        const intervalID = setInterval(shuffle, 7500);
        return () => clearInterval(intervalID);
    }, [shuffle])
  
    return(
        <div className="rotating-image"> 
          <img src={props.images[index]} alt="rotate"/>
        </div>       
    )
  }


  return (
      <div className="home">
        <header className="home-header">
          <div className="animated">
              <div className='actual-text'>
                <h1 className="static-text">Hey! I'm Miles, and I'm a </h1>
                <RotatingText text={description} className="rotating-text"/>
              </div>
              <RotatingImage images={images}/>
          </div>
          <br />
          <div className="about-me"> 
            <br />
              <h3> More About Me: </h3>
              <br />
              <h3> I was born and raised in Austin, Texas. 
                Since graduating from Westlake High School, I have been working towards a Bachelors degree in computer science at California Polytechnic State University.
                <br /><br />I am currently a third year at Cal Poly, and I am looking for opportunities to expand my knowledge through personal projects and real world experiences.
                <br /><br />I find joy in being outdoors and staying active. I love to run, play guitar, work out, rock climb, and read. 
                </h3>
            <br />
          </div>
          <br />
          <br />
          

        </header>
      </div> 
    );
  }
  
  export default Home;