function Education() {
    return (
      <div class="education">
      <header class="education-header">
        <h1 className="main-title">Education</h1>
        <div class="grid-container">
          <div className="row">
            <div className="col1">
              <h2>High School:</h2>
            </div>
            <div className="col2">
              <h3>Westlake High School, Austin Texas.</h3>
            </div>
          </div>

          <div className="row">
            <div className="col1">
              <h2>College:</h2>
            </div>
            <div className="col2">
              <h3>California Polytechnic State University, San Luis Obispo.</h3>
              <h3>Pursuing Bachelor of Science degree in Computer Science</h3>
            </div>    
            
          </div>
          <div className="row">
            <div className="col1">
              <h2>Expected Completion:</h2>
            </div>
            <div className="col2">
              <h3>December 2024</h3>
            </div> 
          </div>
          <div className="row">
            <div className="col1">
              <h2>Class Level:</h2>
            </div>
            <div className="col2">
              <h3>Junior</h3>
            </div> 
          </div>
          <div className="row">
            <div className="col1">
              <h2>Relevant Coursework:</h2>
            </div>
            <div className="col2">
              <h3>Calculus l, Calculus ll, Calculus lll, Linear Analysis, Statistical Methods for Engineers, Introduction to Computing, Fundamentals of Computer Science, Data Structures, Introduction to Computer Organization, Project-Based Object-Oriented Programming, Ethics of Science and Technology</h3> 
            </div>    
          </div>

          <div className="row">
            <div className="col1">
              <h2>Campus Involvement:</h2>
            </div>
            <div className="col2">
              <h3>Cal Poly Week Of Welcome, Leader</h3>
              <h4>Led a group of twenty-five first-year students for five days as a part of the New Student & Transition Programs</h4> 
              <h3>Cal Poly Distance Club, Member</h3>
              <h4>Participated in competitive races as a member of the distance (running) club, and volunteered for club-related events and fundraisers.</h4>
            </div> 
          </div>
      </div>
    </header>
    </div>
    );
  }
  
  export default Education;