import vscode from './images/vscode.png';
import rstudio from './images/rstudio.png';
import github from './images/github.png';
import cissp from './images/cissp.png';

import { useState, useEffect } from "react";

function Skills() {
const [invert, setInvert] = useState(false)
  useEffect(() => {
    const handleBodyClassChange = () => {
      if (document.body.classList.contains('dark-theme')) {
        setInvert(true)
      } else {
        setInvert(false)
      }

    };
    document.body.addEventListener('change', handleBodyClassChange);
    return () => {
      document.body.removeEventListener('change', handleBodyClassChange);
    };
  }, [document.body.classList]);
  
  useEffect(() => {
      if (document.body.classList.contains('dark-theme')) {
        setInvert(true)
      } else {
        setInvert(false)
      }
  }, []);


    return (
      <div className="skills">
        <h1 className="main-title">Technical Abilities</h1>
        <header className="skills-header">
          <div className="upper">
            <div className="tech">
              <h2>Programming Languages:</h2>
              <br />
              <br />

              <ul className='languages'> 
                <li className='list-elem'><a href="https://www.python.org" target="_blank" rel="noopener noreferrer" >Python</a></li>
                <li className='list-elem'><a href="https://www.java.com/en/" target="_blank" rel="noopener noreferrer" >Java</a></li>
                <li className='list-elem'><a href="https://en.wikipedia.org/wiki/C%2B%2B" target="_blank" rel="noopener noreferrer">C++</a></li>
                <li className='list-elem'><a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noopener noreferrer">HTML/CSS</a></li>
                <li className='list-elem'><a href="https://www.r-project.org/" target="_blank" rel="noopener noreferrer">R</a></li>
              </ul>
              <br />

            </div>
            <div className="cert">
              <h2>Certifications:</h2> 
                <div className="cert-logos">
                  <a href="https://www.isc2.org/certifications/cissp" target="_blank" rel="noopener noreferrer">
                    <img src={cissp} className="cissp-main" alt="cissp-logo" />
                  </a>             
                </div>
                <br />
            </div>
            <div className="soft">
              <h2>Software Tools:</h2> 
              <br />
                <div className="software-logos">
                  <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">
                    <img src={vscode} className="vscode-main" alt="vscode-logo" />
                  </a>
                  <a href="https://posit.co/download/rstudio-desktop/" target="_blank" rel="noopener noreferrer">
                    <img src={rstudio} className="rstudio" alt="rstudio-logo" />
                  </a>
                  <a href="https://www.github.com" target="_blank" rel="noopener noreferrer">
                    <img src={github} className="github-main" alt="github-logo" style={{ filter: invert ? 'invert(100%)' : 'none' }}/>
                  </a>              
                </div>
            </div>

          </div>
  
        </header>
      </div>
    );
  }
  
  export default Skills;